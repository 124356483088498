import { cookies } from 'bootstrap-italia/src/js/plugins/util/cookies'
import { loadPlugin } from 'bootstrap-italia/src/js/load-plugin'
import init from 'bootstrap-italia/src/js/plugins/init'
import * as icons from './icons'

import {
    // Bootstrap
    Alert,
    Button,
    //Carousel,
    Collapse,
    Dropdown,
    Modal,
    //Offcanvas,
    //Popover,
    ScrollSpy,
    Tab,
    Toast,
    Tooltip,
    // Extra components
    //Dimmer,
    Notification,
    //Cookiebar,
    NavBarCollapsible,
    Accordion,
    NavScroll,
    //CarouselBI,
    FormValidate,
    ValidatorSelectAutocomplete,
    Input,
    SelectAutocomplete,
    InputSearchAutocomplete,
    InputPassword,
    InputNumber,
    //ProgressDonut,
    //UploadDragDrop,
    BackToTop,
    AcceptOverlay,
    Sticky,
    HeaderSticky,
    HistoryBack,
    //Forward,
    //Masonry,
    List,
    //Transfer,
    //VideoPlayer,
  } from 'bootstrap-italia/src/js/bootstrap-italia.esm'

  loadPlugin(icons)
  init()
  
  export default {
    Alert,
    Button,
    //Carousel,
    Collapse,
    Dropdown,
    Modal,
    //Offcanvas,
    //Popover,
    ScrollSpy,
    Tab,
    Toast,
    Tooltip,
    //VideoPlayer,
    AcceptOverlay,
    Accordion,
    BackToTop,
    //CarouselBI,
    //Cookiebar,
    //Dimmer,
    FormValidate,
    //Forward,
    HistoryBack,
    Input,
    InputNumber,
    InputPassword,
    InputSearchAutocomplete,
    List,
    //Masonry,
    NavBarCollapsible,
    NavScroll,
    Notification,
    //ProgressDonut,
    SelectAutocomplete,
    Sticky,
    HeaderSticky,
    //Transfer,
    //UploadDragDrop,
    ValidatorSelectAutocomplete,
    //loadFonts,
    cookies,
  }
  